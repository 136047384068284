<template>
  <v-container fluid>
    <v-tabs v-model="tab" dense height="30px">
      <v-tab>
        Sinh viên
      </v-tab>
      <v-tab>
        Giám thị
      </v-tab>
    </v-tabs>
    <v-row v-if="tab === 0">
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <v-select
              item-text="fullName"
              item-value="id"
              hide-details
              :items="subjects"
              label="Học phần"
              v-model="params.subjectId"
              @change="getStudentByExamination"
            ></v-select
          ></v-col>
          <v-col cols="6">
            <v-select
              v-model="filter.classId"
              :items="classes"
              hide-details
              label="Lớp"
              dense
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="filter.studyGroupName"
              :items="groups"
              hide-details
              label="Nhóm"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-btn
              @click="saveStudents"
              class="d-block mx-auto mt-1"
              color="blue"
              >Lưu</v-btn
            ></v-col
          >

          <v-col cols="4"
            ><v-text-field
              type="number"
              v-model="selection.from"
              dense
              hide-details=""
              label="Từ STT"
            >
            </v-text-field
          ></v-col>
          <v-col cols="4"
            ><v-text-field
              type="number"
              v-model="selection.to"
              dense
              hide-details=""
              label="Đến STT"
            >
            </v-text-field
          ></v-col>
          <v-col cols="4"
            ><v-btn @click="selectStudents" small>Chọn</v-btn></v-col
          >
          <v-col cols="12">
            <v-data-table
              disable-pagination
              hide-default-footer
              v-model="selectedStudents"
              :headers="headers"
              height="calc(100vh - 390px)"
              show-select
              :items="filteredStudents"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <h3 class="mt-5">Danh sách sinh viên</h3>
            <v-data-table
              class="mt-3"
              disable-pagination
              hide-default-footer
              :headers="headers"
              height="calc(100vh - 220px)"
              :items="currentStudent"
            >
            </v-data-table
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="6">
        <v-data-table
          disable-pagination
          v-model="selectedUsers"
          hide-default-footer
          :headers="[
            { text: 'Tên', value: 'name' },
            { text: 'Email', value: 'email' }
          ]"
          height="calc(100vh - 140px)"
          :items="officers"
          show-select
        >
        </v-data-table>
      </v-col>
      <v-col cols="6"><v-btn @click="saveUsers" color="blue">Lưu</v-btn></v-col>
    </v-row>
  </v-container>
</template>
<script>
import {
  getStudentsByExamination,
  saveStudents,
  show,
  getOfficers,
  saveUsers
} from "@/api/room";
import { getSubjects } from "@/api/original-exam";
export default {
  data() {
    return {
      room: null,
      tab: 0,
      selectedStudents: [],
      selectedUsers: [],
      currentStudent: [],
      selection: {
        from: "",
        to: ""
      },
      headers: [
        { text: "Lớp thi", value: "classId" },
        { text: "STT", value: "numbOrder" },
        { text: "Tên", value: "studentName" },
        { text: "MSSV", value: "studentCode" },
        { text: "Nhóm", value: "studyGroupName" }
      ],
      subjects: [],
      groups: [],
      classes: [],
      students: [],
      officers: [],
      params: {
        subjectId: "",
        sortBy: "id"
      },
      filter: {
        classId: "",
        studyGroupName: ""
      }
    };
  },
  computed: {
    filteredStudents() {
      return this.students.filter(s => {
        if (s.classId !== this.filter.classId) return false;
        if (s.studyGroupName !== this.filter.studyGroupName) return false;
        return true;
      });
    }
  },
  methods: {
    selectStudents() {
      const { to, from } = this.selection;
      if (
        +to <= 0 ||
        +from <= 0 ||
        +to < +from ||
        +to > this.filteredStudents.length
      )
        return this.$snackbar("Nhập không hợp lệ", "error");
      const selectedStudents = this.filteredStudents.slice(
        this.selection.from - 1,
        +this.selection.to
      );

      for (const s of selectedStudents) {
        if (this.selectedStudents.find(item => item.id === s.id)) continue;
        this.selectedStudents.push(s);
      }

      this.selection.to = "";
      this.selection.from = "";
    },
    async getCurrentStudents() {
      const data = await getStudentsByExamination({
        roomId: this.$route.params.id,
        sortBy: "id"
      });
      this.currentStudent = data;
    },
    async getStudentByExamination() {
      this.$loader(true);
      const data = await getStudentsByExamination({
        ...this.params,
        examinationId: this.room.examinationId
      });
      this.students = data;
      const groupSet = new Set();
      const classSet = new Set();
      for (const s of this.students) {
        groupSet.add(s.studyGroupName);
        classSet.add(s.classId);
      }

      this.selectedStudents = this.students.filter(
        item => item.roomId === +this.$route.params.id
      );

      this.groups = Array.from(groupSet);
      this.classes = Array.from(classSet);
      this.filter.studyGroupName = this.groups[0];
      this.filter.classId = this.classes[0];

      this.$loader(false);
    },
    async getSubjects() {
      const { data } = await getSubjects({
        examinationId: this.room.examinationId
      });
      this.subjects = data;
    },
    async getOfficers() {
      const { data } = await getOfficers();
      this.officers = data;
      for (const officer of this.officers) {
        if (this.room.users.find(item => item.email === officer.email)) {
          this.selectedUsers.push(officer);
        }
      }
    },
    async saveStudents() {
      this.$loader(true);
      await saveStudents(this.$route.params.id, {
        students: this.selectedStudents.map(item => item.id)
      });
      this.$loader(false);
      this.$snackbar("Lưu sinh viên thành công", "success");
      this.getCurrentStudents();
    },
    async saveUsers() {
      try {
        this.$loader(true);
        await saveUsers(this.$route.params.id, {
          officers: this.selectedUsers
        });
        this.$loader(false);
        this.$snackbar("Lưu giám thị thành công", "success");
      } catch (error) {
        console.log(error);
        this.$loader(false);
      }

      // this.getCurrentStudents();
    }
  },
  async created() {
    this.room = (await show(this.$route.params.id)).data;

    this.getSubjects();
    this.getCurrentStudents();
    this.getOfficers();
  }
};
</script>