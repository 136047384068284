import request from "@/utils/request";
export function index(params) {
    return request({
        url: "/rooms",
        method: "get",
        params,
    });
}
export function update(id, data) {
    return request({
        url: "/rooms/" + id,
        method: "put",
        data,
    });
}
export function show(id) {
    return request({
        url: "/rooms/" + id,
        method: "get",
    });
}
export function store(data) {
    return request({
        url: "/rooms",
        method: "post",
        data,
    });
}
export function destroy(id) {
    return request({
        url: "/rooms/" + id,
        method: "delete",
    });
}

export function getStudentsByExamination(params) {
    return request({
        url: "/students-by-examination",
        method: "get",
        params,
    });
}
export function getOfficers() {
    return request({
        url: "/officers",
        method: "get",
        params: {
            sortBy: "email",
        },
    });
}

export function saveStudents(id, data) {
    return request({
        url: "/rooms/" + id + "/students",
        method: "post",
        data,
    });
}
export function saveUsers(id, data) {
    return request({
        url: "/rooms/" + id + "/users",
        method: "post",
        data,
    });
}
export function getStudents() {
    return request({
        url: "/rooms/" + id + "/students",
        method: "get",
    });
}
export function getUsers() {
    return request({
        url: "/rooms/" + id + "/users",
        method: "get",
    });
}
