import request from "@/utils/request";

export function index(params) {
  return request({
    url: "/original-exams",
    method: "get",
    params,
  });
}
export function update(id, data) {
  return request({
    url: "/original-exams/" + id,
    method: "put",
    data,
  });
}
export function show(id, params) {
  return request({
    url: "/original-exams/" + id,
    method: "get",
    params,
  });
}
export function getQuestions(id) {
  return request({
    url: "/original-exams/" + id + "/questions",
    method: "get",
  });
}
export function createQuestion(id, data) {
  return request({
    url: "/original-exams/" + id + "/questions",
    method: "post",
    data,
  });
}

export function deleteQuestion(id) {
  return request({
    url: "/questions/" + id,
    method: "delete",
  });
}
export function updateCorrectChoices(id, data) {
  return request({
    url: "/questions/" + id + "/correct-choices",
    method: "put",
    data,
  });
}
export function store(data) {
  return request({
    url: "/original-exams",
    method: "post",
    data,
  });
}
export function destroy(id) {
  return request({
    url: "/original-exams/" + id,
    method: "delete",
  });
}
export function getSubjects(params) {
  return request({
    url: "/list-subjects",
    method: "get",
    params,
  });
}
export function generateExam(data) {
  return request({
    url: "/generate-exams",
    method: "post",
    data,
  });
}

export function getExaminations(params) {
  return request({
    url: "/list-examinations",
    method: "get",
    params,
  });
}

export function getExams(params) {
  return request({
    url: "/exams",
    method: "get",
    params,
  });
}
export function deleteExams(params) {
  return request({
    url: "/exams",
    method: "delete",
    params,
  });
}

export function exportOriginalExam(id) {
  return request({
    url: "/original-exams/" + id + "/export",
    method: "get",
  });
}
export function importOriginalExam(id, data) {
  return request({
    url: "/original-exams/" + id + "/import",
    method: "post",
    data,
  });
}

export function deleteAllQuestion(id) {
  return request({
    url: "/original-exams/" + id + "/deleteAllQuestion",
    method: "delete",
  })
}